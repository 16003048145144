<template>
  <v-container class="app-page app-solicitar">
    <v-card class="overflow-hidden text-center">
      <h1 class="titulo">Adelantos</h1>
      <h3 class="subtitulo">¿Cual es el monto que deseas solicitar ?</h3>
      <v-form ref="adelantoForm" @submit.prevent="createAdelanto">
        <v-text-field
          v-model="object.monto"
          type="number"
          label="importe"
          prefix="$"
          placeholder="Ingresar monto"
          :rules="[rules.required, rules.number]"
          class="input-importe"
        ></v-text-field>
        <v-text-field
          v-model="object.mensaje"
          label="aclaración"
          placeholder="observaciones"
          :rules="[rules.required]"
          class="input-aclaracion"
        ></v-text-field>
        <v-select 
          v-model="object.idContrato"
          :items="collections.contratos"
          :item-text="item => item.NA + ' ->' + item.Cargo"
          item-value="Id_Contrato"
          label="Nº contrato"
          placeholder="selecciona el contrato"
          :rules="[rules.required]"
          class="input-tipo">
        </v-select>
        <v-select
          v-model="object.idConcepto"
          :items="collections.tipos_adelanto"
          item-text="Nombre"
          item-value="ID_Concepto"
          label="solicitar"
          placeholder="selecciona el tipo de solicitud"
          :rules="[rules.required]"
          class="input-tipo"
        ></v-select>
        <v-btn type="submit" :disabled="saving" :loading="saving" class="btn primary mt-10">Solicitar</v-btn>
      </v-form>

      <v-data-table
        :headers="computedHeaders"
        :items="items"
        :single-expand="true"
        :expanded.sync="expanded" 
        show-expand
        item-key="idSolAdel"
        class="app-table"
        :hide-default-footer="true"
        mobile-breakpoint="0"
      >

        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-0">
              <v-simple-table class="app-table-child">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>N°</th>
                      <th>Fecha</th>
                      <th>Acción</th>
                      <th>Usuario</th>
                      <th>Memo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="record in item.lstLegajo" :key="record.idLegAdel">
                      <td>{{ record.idLegAdel }}</td>
                      <td>{{ formatDate(record.FecLeg) }}</td>
                      <td>{{ record.descEstLeg }}</td>
                      <td>{{ record.UsuNombre }}</td>
                      <td>{{ record.MensAcc }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>              
            </td>
        </template>

        <template v-slot:item.monto="{ item }"> 
          {{ item.monto | format_money }}
        </template>

        <template v-slot:item.fecSol="{ item }"> 
          {{ formatDate(item.fecSol) }}
        </template>

        <template v-slot:item.estado="{ item }">
          <v-chip :color="getColor(item.estado)" dark>{{ item.estado }}</v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn x-small icon class="mr-2 btn-accion">
            <v-icon small class="mr-2" @click="deleteItem(item)">mdi-delete</v-icon>
          </v-btn>
          <v-btn x-small icon class="mr-2 btn-accion">    
            <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          </v-btn>
          <v-btn v-if="smallScreen" x-small icon class="btn-accion">    
            <v-icon small @click="verItem(item)">mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogAlert" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Seguro quieres eliminar la solicitud ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="dialogAlert = false">Cancelar</v-btn>
          <v-btn color="primary darken-1" text @click="confirmDeleteItem()" :disabled="deleting" :loading="deleting">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogUpdate" persistent max-width="390">
      <v-card>
        <v-card-title class="headline">Modificar Adelanto</v-card-title>
        <v-card-text>
          <v-form ref="adelantoUpdateForm">
            <v-text-field
              v-model="currentObject.monto"
              type="number"
              label="importe"
              prefix="$"
              placeholder="Ingresar monto"
              :rules="[rules.required, rules.number]"
              class="input-importe"
            ></v-text-field>
            <v-text-field
              v-model="currentObject.mensaje"
              label="aclaración"
              placeholder="observaciones"
              :rules="[rules.required]"
              class="input-aclaracion"
            ></v-text-field>
            <v-select 
              v-model="currentObject.idContrato"
              :items="collections.contratos"
              :item-text="item => item.NA + ' ->' + item.Cargo"
              item-value="Id_Contrato"
              label="Nº contrato"
              placeholder="selecciona el contrato"
              :rules="[rules.required]"
              color="secondary"
              readonly
              class="input-tipo">
            </v-select>
            <v-select
              v-model="currentObject.idConcepto"
              :items="collections.tipos_adelanto"
              item-text="Nombre"
              item-value="ID_Concepto"
              label="solicitar"
              placeholder="selecciona el tipo de solicitud"
              :rules="[rules.required]"
              class="input-tipo"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="dialogUpdate = false">Cancelar</v-btn>
          <v-btn color="primary" text @click="updateAdelanto()" :disabled="updating" :loading="updating">Modificar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogInfo" max-width="390" v-if="currentItem">
      <v-card>
        <v-card-title class="headline">Detalle del Adelanto</v-card-title>
        <v-card-text>
            <v-text-field
              :value="currentItem.idSolAdel"
              label="ID"
              class="input-importe"
              dense
              readonly
            ></v-text-field>
            <v-text-field
              :value="currentItem.Id_Persona"
              label="Nº Cont"
              dense
              readonly
            ></v-text-field>
            <v-text-field
              :value="formatDate(currentItem.fecSol)"
              label="Solicitado"
              dense
              readonly
            ></v-text-field>
            <v-text-field
              :value="currentItem.NombreAdel"
              label="Tipo"
              dense
              readonly
            ></v-text-field>
            <v-text-field
              :value="currentItem.descEstado"
              label="Estado"
              dense
              readonly
            ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="dialogInfo = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getAdelantos, solicitarAdelanto, actualizarAdelanto, eliminarAdelanto } from "@/api/core";
import { mapGetters } from "vuex";
import { hasPermission } from '@/utils/permission';
import { formatMoney } from "@/utils/index";

export default {
  name: "adelantos",
  data: () => ({
    area: 3,
    smallScreen: false,
    dialogAlert: false,
    dialogUpdate: false,
    dialogInfo: false,
    expanded: [],

    loading: false,
    saving: false,
    deleting: false,
    updating: false,

    items: [],

    headers: [
      {
        text: "ID",
        align: "center",
        sortable: false,
        value: "idSolAdel",
      },
      { text: "Nº Cont", align: "center", value: "Id_Persona" },
      //{ text: "Apellido", value: "apellido" },
      //{ text: "Nombre", value: "nombre" },
      { text: "Solicitado", value: "fecSol" },
      { text: "Tipo", value: "NombreAdel" },
      { text: "Estado", value: "descEstado", align: "center" },
      { text: "Monto", value: "monto" },
      { text: "Acciones", value: "actions", sortable: false, align: "center" },
      { text: '', value: 'data-table-expand' },
    ],

    filters: {
      estados: [],
      fechas: [],
      idEstadoSel: null,
      month: null,
    },

    object: {
      monto: null,
      idConcepto: null,
      idContrato: null,
      mensaje: null,
    },

    currentObject: {
      idSolicitud: null,
      monto: null,
      idConcepto: null,
      idContrato: null,
      mensaje: null,
    },

    currentItem: null,

    rules: {
      required: value => !!value || 'Requerido.',
      number: value => {
        const pattern = /^([0-9]+)$/
        return pattern.test(value) || 'Monto invalido.'
      }
    },
  }),

  computed: {
    ...mapGetters(["user", "collections"]),

    computedHeaders () {
      return this.headers.filter( item => {
        if(this.smallScreen)
          return item.value != 'idSolAdel' && item.value != 'Id_Persona' && item.value != 'fecSol'
        return true
      })
    },
  },

  mounted() {
    this.$store.commit("setNavigationDrawer", false);
    this.$store.commit("setBackButton", false);
    this.$store.commit("setToolbarTitle", "Adelantos");
    this.$store.commit("stopProcessing");
    this.smallScreen = window.innerWidth < 600
    if(!this.hasPermission(this.area)) {
      this.$router.go(-1)
    } else {
      this.initData()
    }
  },

  filters: {
    format_money: function(value) {
      return formatMoney(value)
    }
  },

  methods: {
    hasPermission,
    formatDate (datetime) {
      if (!datetime) return null

      const [date, time] = datetime.split(' ')
      const [year, month, day] = date.split('-')
      if(time)
        return `${day}/${month}/${year} ${time}`
      return `${day}/${month}/${year}`
    },

    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    getContrato(id) {
      let record = this.collections.contratos.find(element => element.Id_Contrato == id)
      return record ? record.Cargo : ""
    },

    getColor(estado) {
      if (estado == "aprobado") return "aprobado";
      else if (estado == "pendiente") return "pendiente";
      else return "rechazado";
    },

    async initData() {
      try {
        this.loading = true

        const data = await getAdelantos()

        this.items = data.records
        this.filters.estados = data.estados

        this.loading = false
      } catch(e) {
        console.log(e)
        this.loading = false
      }
    },

    async createAdelanto() {
      if(this.$refs.adelantoForm.validate(true)) {
        this.saving = true
        const params = Object.assign({}, this.object);
        try {
          const data = await solicitarAdelanto(params)
          const isError = Object.prototype.hasOwnProperty.call(data, "ErrNro") && data.ErrNro != 0
          this.$store.commit("setSnackBar", {
            message: isError
              ? Object.prototype.hasOwnProperty.call(data, 'ErrNro') ? data.ErrDescripcion : "Ha ocurrido un error al solicitar el adelanto!" 
              : "Has solicitado el adelanto correctamente!",
            show: true,
            color: isError ? "#cc0605" : "#008f39",
            timeout: 10000,
          })
          this.cleanObject()
          this.$refs.adelantoForm.resetValidation()
          this.saving = false
          if(!isError) this.initData()
        } catch(e) {
          this.saving = false
        }
      }
    },

    deleteItem(item) {
      this.currentObject.idSolicitud = item.idSolAdel
      this.dialogAlert = true
    },

    async confirmDeleteItem() {
      try {
        this.deleting = true
        let params = { idSolicitud: this.currentObject.idSolicitud }

        const data = await eliminarAdelanto(params)

        const index = this.items.findIndex(item => item.idSolAdel == this.currentObject.idSolicitud )
        this.items.splice(index, 1)

        const isError = Object.prototype.hasOwnProperty.call(data, "ErrNro") && data.ErrNro != 0
        this.$store.commit("setSnackBar", {
          message: isError ? data.ErrDescripcion : "Has eliminado el adelanto correctamente!",
          show: true,
          color: isError ? "#cc0605" : "#008f39",
          timeout: 10000,
        })

        this.deleting = false
        this.dialogAlert = false
      } catch (e) {
        this.deleting = false
        this.dialogAlert = false
      }
    },


    editItem(item) {
      this.currentObject.idSolicitud = item.idSolAdel
      this.currentObject.monto = item.monto
      this.currentObject.idConcepto = item.id_Concepto
      this.currentObject.idContrato = parseInt(item.Id_Contrato)
      this.currentObject.mensaje = null

      this.dialogUpdate = true
    },

    verItem(item) {
      this.currentItem = item

      this.dialogInfo = true
    },

    async updateAdelanto() {
      if(this.$refs.adelantoUpdateForm.validate(true)) {
        this.updating = true
        const params = Object.assign({}, this.currentObject);
        try {
          const data = await actualizarAdelanto(params)
          const isError = Object.prototype.hasOwnProperty.call(data, "ErrNro") && data.ErrNro != 0
          this.$store.commit("setSnackBar", {
            message: isError ? data.ErrDescripcion : "Has modificado el adelanto correctamente!",
            show: true,
            color: isError ? "#cc0605" : "#008f39",
            timeout: 10000,
          })
          this.cleanCurrentObject()
          this.$refs.adelantoUpdateForm.resetValidation()
          this.updating = false
          this.dialogUpdate = false
          if(!isError) this.initData()
        } catch(e) {
          this.updating = false
          this.dialogUpdate = false
        }
      }
    },

    cleanObject() {
      this.object = {
        monto: null,
        idConcepto: null,
        idContrato: null,
        mensaje: null,
      }
    },

    cleanCurrentObject() {
      this.currentObject = {
        idSolicitud: null,
        monto: null,
        idConcepto: null,
        idContrato: null,
        mensaje: null,
      }
    }
  }
};
</script>
